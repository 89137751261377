import {
  Box,
  Button,
  Chip,
  Container,
  Grid,
  Icon,
  List,
  ListItem,
  Stack,
  Typography
} from "@mui/material";
import Aos from "aos";
import { useEffect, useRef, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";

export default function Home() {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let _count = 1;

    setInterval(() => {
      if (_count < 10000000) {
        _count += 1000;
        setCount(_count);
      }
    }, 10);
  }, []);

  useEffect(() => {
    Aos.refresh();
  }, []);

  return (
    <>
      <Box
        sx={{
          mb: 20
        }}
      >
        <Container maxWidth={"lg"}>
          <Grid container alignItems={"center"}>
            <Grid item sx={{ width: "550px" }}>
              <Box sx={{ position: "relative" }}>
                <Box
                  sx={{
                    width: "100%",
                    height: 500,
                    backgroundImage: 'url("/youtube.png")',
                    backgroundSize: "cover",
                    filter: "blur(8px)",
                    animation: "player-grow 3s infinite"
                  }}
                ></Box>
                <Box sx={{ position: "absolute", top: 0, left: 0 }}>
                  <Box sx={{ p: 5, pb: 0 }}>
                    <Box className="hvr-grow-shadow">
                      <img src="/youtube.png" width="100%" />
                    </Box>
                  </Box>
                  <Box display={"flex"} justifyContent="center">
                    <Stack direction="row" spacing={2} alignItems={"center"}>
                      <Typography fontSize={"2.3rem"}>
                        {count.toLocaleString()}
                      </Typography>
                      <Typography>viewed</Typography>
                    </Stack>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs>
              <Box sx={{ textAlign: "right" }}>
                <Typography fontSize={"4.2rem"} sx={{ animation: "tada 1s" }}>
                  BoostUP engagement your video.
                </Typography>
                <Typography
                // sx={{ animation: "tada 1s infinite", animationDelay: ".4s" }}
                >
                  fast increase video view
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box
        sx={{
          py: 5,
          background: "rgb(71,78,84)",
          background:
            "-moz-linear-gradient(90deg, rgba(71,78,84,.4) 0%, rgba(44,62,78,.4) 100%)",
          background:
            "-webkit-linear-gradient(90deg, rgba(71,78,84,.4) 0%, rgba(44,62,78,.4) 100%)",
          background:
            "linear-gradient(90deg, rgba(71,78,84,.4) 0%, rgba(44,62,78,.4) 100%)",
          filter:
            'progid:DXImageTransform.Microsoft.gradient(startColorstr="#474e54",endColorstr="#2c3e4e",GradientType=1)'
        }}
      >
        <Container maxWidth={false}>
          <Box display={"flex"} justifyContent="center">
            <Box>
              <Typography color="#aaa">Total Supply</Typography>
              <Box display={"inline-block"}>
                <Typography
                  fontSize={"3.4rem"}
                  sx={{
                    textShadow: "1px 1px 2px #333"
                  }}
                >
                  100,000,000 <Chip label="adsx" />
                </Typography>
              </Box>
            </Box>
            <Box sx={{ mx: 10 }}>
              <Typography color="#aaa">Total Pool Prize</Typography>
              <Box display={"inline-block"}>
                <Typography
                  fontSize={"3.4rem"}
                  sx={{
                    textShadow: "1px 1px 2px #333"
                  }}
                >
                  $ 120,239,233
                </Typography>
              </Box>
            </Box>
            <Box>
              <Typography color="#aaa">Adsx Price</Typography>
              <Box display={"inline-block"}>
                <Typography
                  fontSize={"3.4rem"}
                  sx={{
                    textShadow: "1px 1px 2px #333"
                  }}
                >
                  $ 2.3
                </Typography>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box>
        <Container maxWidth={"lg"}>
          <Box sx={{ position: "relative", height: 1100 }}>
            <Box
              sx={{
                position: "absolute",
                top: 100,
                left: 200,
                width: 400,
                height: 300
              }}
              data-aos="fade-left"
            >
              <Typography fontSize={"3.4rem"}>
                1. Copy Your Youtube URL
              </Typography>
            </Box>
            <Box
              sx={{
                position: "absolute",
                top: 350,
                right: 0,
                width: 300,
                height: 300
              }}
              data-aos="fade-right"
            >
              <Typography fontSize={"3rem"}>
                2. Add To Your Viewe Channel
              </Typography>
            </Box>
            <Box
              sx={{
                position: "absolute",
                top: 570,
                left: 50,
                width: 400,
                height: 300
              }}
              data-aos="fade-left"
            >
              <Typography fontSize={"4rem"}>
                3. Get Huge View In Minutes
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box sx={{ my: 10 }}>
        <Container maxWidth={false}>
          <Typography align="center" fontSize={"3.5rem"} mb={3}>
            Roadmap
          </Typography>
          <Swiper
            spaceBetween={50}
            slidesPerView={3}
            onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper) => console.log(swiper)}
            modules={[Pagination, Navigation]}
            navigation={true}
          >
            {[...new Array(4)].map(() => (
              <SwiperSlide>
                <Box
                  sx={{
                    p: 4,
                    height: 350,
                    background: "rgb(71,78,84)",
                    background:
                      "-moz-linear-gradient(90deg, rgba(71,78,84,1) 0%, rgba(44,62,78,1) 100%)",
                    background:
                      "-webkit-linear-gradient(90deg, rgba(71,78,84,1) 0%, rgba(44,62,78,1) 100%)",
                    background:
                      "linear-gradient(90deg, rgba(71,78,84,1) 0%, rgba(44,62,78,1) 100%)",
                    filter:
                      'progid:DXImageTransform.Microsoft.gradient(startColorstr="#474e54",endColorstr="#2c3e4e",GradientType=1)',
                    borderRadius: "15px"
                  }}
                >
                  <Typography textAlign={"center"}>Q4 2022</Typography>
                  <List>
                    <ListItem>
                      <Typography>Launch Trading Fund management</Typography>
                    </ListItem>
                    <ListItem>
                      <Typography>
                        Launch Beta Farming Fund management
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography>Launch Beta Pool Rebalancing</Typography>
                    </ListItem>
                    <ListItem>
                      <Typography>
                        Apply to VC and Chains incubator program
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography>Launch IDO Token</Typography>
                    </ListItem>
                    <ListItem>
                      <Typography>Farm and Stake Netefi Token</Typography>
                    </ListItem>
                    <ListItem>
                      <Typography>
                        List in Coinmarketcap and Coingecko
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography>Cross Chain in Lab</Typography>
                    </ListItem>
                  </List>
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        </Container>
      </Box>
      <Box sx={{ py: 3, background: "#f44336" }}>
        <Container maxWidth={false}>
          <Box display={"flex"} justifyContent={"center"}>
            <Stack direction={"row"} spacing={2} alignItems={"center"}>
              <Icon>
                <img src="/hot-deal.png" height={25} />
              </Icon>
              <Typography fontSize={"2rem"}>Become adsx agency at</Typography>{" "}
              <Button
                variant="outlined"
                color="inherit"
                sx={{ fontSize: "2rem" }}
              >
                PinkSale
              </Button>
              <Icon>
                <img src="/hot-deal.png" height={25} />
              </Icon>
            </Stack>
          </Box>
        </Container>
      </Box>
    </>
  );
}
