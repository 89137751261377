import {
  Button,
  Container,
  Dialog,
  DialogContent,
  Typography
} from "@mui/material";
import { useEffect, useRef, useState } from "react";

let _player;
export default function Secure() {
  const ref = useRef();
  const [startCountdown, setStartCountdown] = useState(false);
  const [openDialogReward, setOpenDialogReward] = useState(false);
  const [player, setPlayer] = useState(null);

  useEffect(() => {
    var tag = document.createElement("script");

    tag.src = "https://www.youtube.com/iframe_api";
    var firstScriptTag = document.getElementsByTagName("script")[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  }, []);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    function onYouTubeIframeAPIReady() {
      _player = new window.YT.Player("player", {
        height: "390",
        width: "640",
        videoId: "8oGeh-yLkYY",
        playerVars: { autoplay: 1, controls: 0 },
        events: {
          onReady: onPlayerReady,
          onStateChange: onPlayerStateChange
        }
      });

      setPlayer(_player);
    }

    function onPlayerReady(event) {
      event.target.playVideo();
    }
    function onPlayerStateChange(event) {
      if (event.data == window.YT.PlayerState.PLAYING) {
        setStartCountdown(true);
      }
    }

    setTimeout(() => {
      onYouTubeIframeAPIReady();
    }, 1000);
  }, [ref]);

  useEffect(() => {
    if (!player) {
      return;
    }

    const fetchYTState = () => {
      return (player.playerInfo.currentTime * 100) / player.playerInfo.duration;
    };

    const timer = setInterval(() => {
      window.parent.postMessage(fetchYTState());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [player]);

  useEffect(() => {
    let timer;
    if (startCountdown == true) {
      const timeout = 5.51 * 60 * 1000;
      timer = setTimeout(() => {
        setOpenDialogReward(true);
      }, timeout);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [startCountdown]);

  const handleClose = () => {
    setOpenDialogReward(false);
  };

  return (
    <>
      <div id="player" ref={ref}></div>
      <Dialog open={openDialogReward}>
        <DialogContent>
          <Button variant="contained" fullWidth onClick={handleClose}>
            Receive 0.00013 adsx
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
}
