import {
  Box,
  Card,
  CardContent,
  Chip,
  Container,
  Grid,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { Stack } from "@mui/system";
import { useEffect, useState } from "react";

export default function Video() {
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    window.addEventListener("message", function (message) {
      if (message.origin == "https://viewe.pages.dev") {
        setProgress(message.data);
      }
    });
  }, []);

  return (
    <>
      <Container>
        <Box sx={{ mt: 3 }}></Box>
        <Box sx={{ mb: 1 }}>
          <Box>
            <Typography fontSize={".8rem"}>Ohkie</Typography>
            <Typography fontSize={"1.3rem"} mb={1}>
              How to Connect Metamask to OpenSea?
            </Typography>
          </Box>
          <Stack direction={"row"} spacing={1}>
            <Chip label="5:23 Min" />
            <Chip label="0.00001 adsx" />
          </Stack>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={7}>
            <Box>
              <iframe
                src="/secure?token=xxxx"
                width="640"
                height="390"
                frameBorder={0}
                scrolling="no"
              ></iframe>
            </Box>
            <Box sx={{ width: "100%" }}>
              <LinearProgress variant="determinate" value={progress} />
            </Box>
          </Grid>
          <Grid item xs={5}>
            <Box sx={{ mb: 2 }}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Card>
                    <CardContent>
                      <Typography fontSize={".8rem"} color="#78909c">
                        Creator
                      </Typography>
                      <Typography>0x000000</Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={6}>
                  <Card>
                    <CardContent>
                      <Typography fontSize={".8rem"} color="#78909c">
                        Reward Pool
                      </Typography>
                      <Typography>1.252 Adsx</Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={6}>
                  <Card>
                    <CardContent>
                      <Typography fontSize={".8rem"} color="#78909c">
                        Expire In
                      </Typography>
                      <Typography>4 Days</Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={6}>
                  <Card>
                    <CardContent>
                      <Typography fontSize={".8rem"} color="#78909c">
                        Viewed
                      </Typography>
                      <Typography>23,212</Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography mb={1}>View Transaction</Typography>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ color: "#78909c" }}>Viewer</TableCell>
                      <TableCell sx={{ color: "#78909c" }} width={1}>
                        Reward
                      </TableCell>
                      <TableCell width={1}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>0x000...000</TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                        0.0002 adsx
                      </TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                        3s ago
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
