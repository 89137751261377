import {
  Box,
  Container,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography
} from "@mui/material";
import { Stack } from "@mui/system";

export default function Footer() {
  return (
    <Box sx={{ bgcolor: "primary.main", py: 2 }}>
      <Container maxWidth={"lg"}>
        <Box sx={{ py: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <Typography fontSize={"2.3rem"}>Viewe.io</Typography>
              <Typography>Engentment your video</Typography>
            </Grid>
            <Grid item xs={7} container>
              <Grid item xs={4}>
                <Typography fontWeight={"bold"} mb={1.5}>
                  Docs
                </Typography>
                <List>
                  <ListItem disableGutters>
                    <Link fontSize={".85rem"} underline="none" color="#fff">
                      Whitepaper
                    </Link>
                  </ListItem>
                  <ListItem disableGutters>
                    <Link fontSize={".85rem"} underline="none" color="#fff">
                      Gitbook
                    </Link>
                  </ListItem>
                  <ListItem disableGutters>
                    <Link fontSize={".85rem"} underline="none" color="#fff">
                      How to use
                    </Link>
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={4}>
                <Typography fontWeight={"bold"} mb={1.5}>
                  Community
                </Typography>
                <List>
                  <ListItem disableGutters>
                    <Link fontSize={".85rem"} underline="none" color="#fff">
                      Telegram
                    </Link>
                  </ListItem>
                  <ListItem disableGutters>
                    <Link fontSize={".85rem"} underline="none" color="#fff">
                      Twitter
                    </Link>
                  </ListItem>
                  <ListItem disableGutters>
                    <Link fontSize={".85rem"} underline="none" color="#fff">
                      Youtube
                    </Link>
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={4}>
                <Typography fontWeight={"bold"} mb={1.5}>
                  Event
                </Typography>
                <List>
                  <ListItem disableGutters>
                    <Link fontSize={".85rem"} underline="none" color="#fff">
                      PinkSale
                    </Link>
                  </ListItem>
                  <ListItem disableGutters>
                    <Link fontSize={".85rem"} underline="none" color="#fff">
                      Airdrop
                    </Link>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Box sx={{ my: 2 }}>
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item>
              <Typography>&copy; 2022 viewe.io</Typography>
            </Grid>
            <Grid item>
              <Stack direction={"row"} spacing={2}>
                <Link
                  underline="none"
                  href="/privacy"
                  sx={{ color: "#efefef" }}
                >
                  Privacy Policy
                </Link>
                <Link underline="none" href="/terms" sx={{ color: "#efefef" }}>
                  Terms of Use
                </Link>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}
