import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Link,
  Stack,
  TextField,
  Typography
} from "@mui/material";

export default function Explore() {
  return (
    <Container maxWidth={false} fullWidth>
      <Box sx={{ my: 3 }}>
        <Box display={"flex"} justifyContent="center" sx={{ mb: 2 }}>
          <Stack direction={"row"} alignItems="center" spacing={3}>
            <Typography fontSize="1.5rem">Explore</Typography>
            <Typography color="primary" fontSize="2rem">
              <strong>10,943</strong>
            </Typography>
            <Typography fontSize="1.5rem">Video</Typography>
          </Stack>
        </Box>
        <TextField placeholder="Search" fullWidth />
      </Box>
      <Box sx={{ mb: 3 }}>
        <Grid container spacing={2}>
          {[...new Array(10)].map(() => {
            return (
              <Grid item xs={3}>
                <Box position="relative">
                  <Link href="/video" target="_blank">
                    <Box>
                      <Box
                        component="img"
                        width={"100%"}
                        src="https://img.youtube.com/vi/8oGeh-yLkYY/0.jpg"
                      />
                    </Box>
                  </Link>
                  <Box
                    sx={{
                      borderRadius: "15px 15px 0 0",
                      p: 1,
                      position: "absolute",
                      top: "0",
                      left: "0",
                      width: "100%",
                      width: "100%",
                      background: "rgb(255,255,255)",
                      background:
                        "-moz-linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 50%)",
                      background:
                        "-webkit-linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 50%)",
                      background:
                        "linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 50%)",
                      filter:
                        'progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#000000",GradientType=1)'
                    }}
                  >
                    <Typography fontSize="1.125rem">
                      How to Connect Metamask to OpenSea?
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      borderRadius: "0 0 15px 15px",
                      p: 1,
                      position: "absolute",
                      width: "100%",
                      bottom: 0,
                      left: 0,
                      background: "rgb(0,0,0)",
                      background:
                        "-moz-linear-gradient(0deg, rgba(0,0,0,1) 50%, rgba(255,255,255,0) 100%)",
                      background:
                        "-webkit-linear-gradient(0deg, rgba(0,0,0,1) 50%, rgba(255,255,255,0) 100%)",
                      background:
                        "linear-gradient(0deg, rgba(0,0,0,1) 50%, rgba(255,255,255,0) 100%)",
                      filter:
                        'progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#ffffff",GradientType=1)'
                    }}
                  >
                    <Grid container justifyContent={"space-between"}>
                      <Grid item fontSize={".7rem"}>
                        4:52 Min
                      </Grid>
                      <Grid item fontSize={".7rem"}>
                        0.00001 ADSX
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Container>
  );
}
