import { BrowserRouter, Route, Routes } from "react-router-dom";

import Layout from "./layouts";
import Channel from "./pages/Channel";
import Explore from "./pages/Explore";
import Home from "./pages/Home";
import Reward from "./pages/Reward";
import Video from "./pages/Video";
import Secure from "./pages/Secure";

export default function AppRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/explore" element={<Explore />} />
          <Route path="/channel" element={<Channel />} />
          <Route path="/reward" element={<Reward />} />
          <Route path="/video" element={<Video />} />
        </Route>
        <Route path="/secure" element={<Secure />} />
      </Routes>
    </BrowserRouter>
  );
}
