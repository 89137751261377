import { CssBaseline, GlobalStyles } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DAppProvider } from "@usedapp/core";
import { RecoilRoot } from "recoil";
import AppRouter from "./AppRouter";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

function App() {
  const theme = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none"
          }
        }
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            background: "none",
            boxShadow: "none"
          }
        }
      },
      MuiLink: {
        styleOverrides: {
          root: {
            ":hover": {
              color: "#90caf9"
            }
          }
        }
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            minHeight: "100px !important"
          }
        }
      }
    },
    palette: {
      mode: "dark",
      primary: {
        main: "#64b5f6",
        dark: "#64b5f6",
        light: "#64b5f6",
        contrastText: "#fafafa"
      },
      secondary: {
        main: "#90caf9",
        dark: "#90caf9",
        light: "#90caf9",
        contrastText: "#333"
      }
    },
    typography: {
      fontFamily: "'Paytone One', sans-serif"
    }
  });

  const config = {
    readOnlyChainId: 97,
    readOnlyUrls: {
      97: "https://data-seed-prebsc-1-s1.binance.org:8545"
    }
  };

  return (
    <RecoilRoot>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DAppProvider config={config}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <AppRouter />
          </ThemeProvider>
        </DAppProvider>
      </LocalizationProvider>
    </RecoilRoot>
  );
}

export default App;
