import {
  AppBar,
  Button,
  Container,
  Link,
  Toolbar,
  Box,
  Typography,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Drawer,
  Divider
} from "@mui/material";

import { styled } from "@mui/material/styles";

import { Stack } from "@mui/system";
import { useEthers } from "@usedapp/core";
import { useEffect, useMemo, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { SwipeableDrawer } from "@mui/material";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import Footer from "./Footer";

const ButtonLogin = styled(Button)(({ theme }) => ({
  border: "rgb(41,143,233)",
  color: "#efefef",
  backgroundColor: "rgb(41,143,233)",
  backgroundImage:
    "-moz-linear-gradient(90deg, rgba(41,143,233,1) 0%, rgba(11,96,171,1) 100%)",
  backgroundImage:
    "-webkit-linear-gradient(90deg, rgba(41,143,233,1) 0%, rgba(11,96,171,1) 100%)",
  backgroundImage:
    "linear-gradient(90deg, rgba(41,143,233,1) 0%, rgba(11,96,171,1) 100%)",
  filter:
    'progid:DXImageTransform.Microsoft.gradient(startColorstr="#298fe9",endColorstr="#0b60ab",GradientType=1)',

  "&:hover": {
    border: "rgb(41,143,233)",
    color: "#efefef",
    backgroundColor: "rgb(11,96,171)",
    backgroundImage:
      "-moz-linear-gradient(90deg, rgba(11,96,171,1) 0%, rgba(41,143,233,1) 100%)",
    backgroundImage:
      "-webkit-linear-gradient(90deg, rgba(11,96,171,1) 0%, rgba(41,143,233,1) 100%)",
    backgroundImage:
      "linear-gradient(90deg, rgba(11,96,171,1) 0%, rgba(41,143,233,1) 100%)",
    filter:
      'progid:DXImageTransform.Microsoft.gradient(startColorstr="#0b60ab",endColorstr="#298fe9",GradientType=1)'
  }
}));

export default function Layout() {
  const { activateBrowserWallet, account } = useEthers();
  const navigate = useNavigate();
  const [openDrawerProfile, setOpenDrawerProfile] = useState(false);

  const [el, setEl] = useState(null);

  useEffect(() => {}, []);

  const handleConnectWallet = () => {
    activateBrowserWallet();
  };

  const shortAccount = useMemo(() => {
    if (!account) {
      return "";
    }

    return (
      account.substring(0, 5) + "..." + account.substring(account.length - 5)
    );
  }, [account]);

  const handleOpenDrawerProfile = (event) => {
    setOpenDrawerProfile(true);
  };

  const handleCloseDrawerProfile = () => {
    setOpenDrawerProfile(false);
  };

  const handleClickMenu = (path) => {
    return () => {
      setOpenDrawerProfile(false);
      navigate(path);
    };
  };

  return (
    <>
      <Box sx={{ position: "relative" }}>
        <Box
          sx={{
            backgroundImage:
              "url(https://miro.medium.com/max/1400/1*912pJLEbGCC-i_W_yAqedQ.jpeg)",
            backgroundAttachment: "fixed",
            backgroundSize: "cover",
            backdropFilter: "opacity(80%)",
            position: "fixed",
            top: 0,
            left: 0,
            height: "100vh",
            width: "100vw",
            opacity: 0.1,
            zIndex: -1
          }}
        ></Box>
        <AppBar
          position="fixed"
          sx={{
            background: "rgb(255,255,255)",
            background:
              "-moz-linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(18,30,42,0.713344712885154) 51%, rgba(18,30,42,1) 100%)",
            background:
              "-webkit-linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(18,30,42,0.713344712885154) 51%, rgba(18,30,42,1) 100%)",
            background:
              "linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(18,30,42,0.713344712885154) 51%, rgba(18,30,42,1) 100%)",
            filter:
              'progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#121e2a",GradientType=1)'
          }}
        >
          <Container maxWidth={false}>
            <Toolbar>
              <Box display="flex" alignItems={"center"}>
                <Link
                  underline="none"
                  href="/"
                  fontSize={"2rem"}
                  color={"#fff"}
                >
                  <Stack direction={"row"} alignItems="center">
                    <Typography
                      sx={{ ml: -1 }}
                      fontFamily={"Satisfy"}
                      fontSize="1.4rem"
                      fontWeight="bold"
                    >
                      ViewE
                    </Typography>
                    <img src="/image/play.png" height={25} />
                  </Stack>
                </Link>
                <Box sx={{ ml: 4 }}>
                  <Stack direction={"row"} spacing={3}>
                    <Link
                      href="/explore"
                      underline="none"
                      className="hvr-pop"
                      sx={{ textShadow: "1px 1px 2px #000" }}
                    >
                      Explore
                    </Link>
                    <Link
                      href="/explore"
                      underline="none"
                      className="hvr-pop"
                      sx={{ textShadow: "1px 1px 2px #000" }}
                    >
                      Docs
                    </Link>
                    <Link
                      href="/explore"
                      underline="none"
                      className="hvr-pop"
                      sx={{ textShadow: "1px 1px 2px #000" }}
                    >
                      Buy ADSX
                    </Link>
                    <Link
                      href="/explore"
                      underline="none"
                      className="hvr-pop"
                      sx={{ textShadow: "1px 1px 2px #000" }}
                    >
                      Exchange
                    </Link>
                  </Stack>
                </Box>
              </Box>
              <Box display={"flex"} alignItems="center" sx={{ ml: "auto" }}>
                <Stack direction={"row"} spacing={1} alignItems="center">
                  <Typography>1923.2</Typography>
                  <Typography>ADSX</Typography>
                  <Typography fontSize={".8rem"}>($1.32)</Typography>
                </Stack>
                <Box sx={{ ml: 2 }}>
                  {!shortAccount && (
                    <ButtonLogin
                      variant="outlined"
                      onClick={handleConnectWallet}
                    >
                      Connect Wallet
                    </ButtonLogin>
                  )}
                  {shortAccount && (
                    <IconButton
                      variant="outlined"
                      color="inherit"
                      onClick={handleOpenDrawerProfile}
                    >
                      <AccountBalanceWalletIcon />
                    </IconButton>
                  )}
                </Box>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
        <Toolbar sx={{ mb: 2 }} />
      </Box>
      <Box sx={{ minHeight: "100vh" }}>
        <Outlet />
      </Box>
      <Footer />
      <SwipeableDrawer
        open={openDrawerProfile}
        onClose={handleCloseDrawerProfile}
        anchor="right"
      >
        <Box sx={{ width: 300 }}>
          <Box
            sx={{ p: 2 }}
            display="flex"
            justifyContent={"space-between"}
            alignItems="center"
          >
            <Typography component={"span"} fontSize=".7rem">
              Connect with
            </Typography>{" "}
            <Typography>{shortAccount}</Typography>
          </Box>
          <Divider />
          <Button onClick={handleClickMenu("/channel")} fullWidth>
            My Channel
          </Button>
          <Button onClick={handleClickMenu("/channel")} fullWidth>
            Add New Video
          </Button>
          <Divider />
          <Box sx={{ p: 3 }}>
            <Typography textAlign={"center"} mb={1}>
              reward 22.342 adsx
            </Typography>
            <Box sx={{ textAlign: "center", mb: 1 }}>
              <Button
                variant="outlined"
                onClick={handleClickMenu("/reward")}
                fullWidth
              >
                Claim Reward
              </Button>
            </Box>
            <Box sx={{ textAlign: "center" }}>
              <Link href="/reward" fontSize={".88rem"}>
                Reward History
              </Link>
            </Box>
          </Box>
          <Divider />
          <Box sx={{ p: 3 }}>
            <Typography textAlign={"center"} mb={1}>
              Referral Url
            </Typography>
            <Button
              variant="outlined"
              onClick={handleClickMenu("/reward")}
              fullWidth
            >
              Copy Link
            </Button>
          </Box>
        </Box>
      </SwipeableDrawer>
    </>
  );
}
