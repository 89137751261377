import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
  InputAdornment,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper,
  ButtonGroup,
  Dialog,
  DialogContent,
  RadioGroup,
  FormControlLabel,
  Radio,
  DialogActions
} from "@mui/material";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

import Zoom from "@mui/material/Zoom";
import React, { useState } from "react";
import { Stack } from "@mui/system";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom in ref={ref} {...props} />;
});

function Channel() {
  const [openDialogAddVideo, setOpenDialogAddVideo] = useState(false);

  const handleOpenDialogAddVideo = () => {
    setOpenDialogAddVideo(true);
  };

  const handleOnCloseDialigAddVideo = () => {
    setOpenDialogAddVideo(false);
  };

  return (
    <Container>
      <Box sx={{ mt: 3 }}>
        <Typography textAlign={"center"} sx={{ fontSize: "1.3rem" }} mb={2}>
          ADSCoin Faucet
        </Typography>
        <Box>
          <TextField
            fullWidth
            placeholder="Enter your wallet address"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button edge="end" color="primary">
                    Give 10 ADSCoin
                  </Button>
                </InputAdornment>
              )
            }}
          />
        </Box>
      </Box>
      <Divider sx={{ my: 5 }} />
      <Box sx={{ mb: 1 }}>
        <Grid container justifyContent={"space-between"}>
          <Grid item xs="auto">
            <ButtonGroup
              variant="contained"
              aria-label="outlined primary button group"
            >
              <Button>Ongoing</Button>
              <Button>Done</Button>
            </ButtonGroup>
          </Grid>
          <Grid item xs="auto">
            <Button variant="outlined" onClick={handleOpenDialogAddVideo}>
              Add Video Url
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Video</TableCell>
                <TableCell>Duration</TableCell>
                <TableCell>Expire</TableCell>
                <TableCell>Spend Out</TableCell>
                <TableCell width={1}></TableCell>
                <TableCell width={1}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                How to Connect Metamask to OpenSea?
                </TableCell>
                <TableCell>3.6 Min</TableCell>
                <TableCell>6 days</TableCell>
                <TableCell>0.92415 Adscoin</TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    color="warning"
                    startIcon={<PauseIcon />}
                  >
                    Pause
                  </Button>
                </TableCell>
                <TableCell>
                  <Button variant="outlined" endIcon={<PlayArrowIcon />}>
                    Resume
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Dialog
        open={openDialogAddVideo}
        TransitionComponent={Transition}
        onClose={handleOnCloseDialigAddVideo}
        maxWidth="md"
        fullWidth
      >
        <DialogContent dividers sx={{ p: 4 }}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={8}>
              <Stack spacing={2}>
                <Box sx={{ mt: 1.5 }}>
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e1/Logo_of_YouTube_%282015-2017%29.svg/2560px-Logo_of_YouTube_%282015-2017%29.svg.png"
                    height={25}
                  />
                </Box>
                <TextField placeholder="Enter youtube url" fullWidth />
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <DatePicker
                        label="Start"
                        onChange={() => {}}
                        renderInput={(params) => {
                          return <TextField fullWidth {...params} />;
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <DatePicker
                        label="End"
                        onChange={() => {}}
                        renderInput={(params) => {
                          return <TextField fullWidth {...params} />;
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack spacing={2}>
                <Box textAlign={"center"}>
                  <RadioGroup
                    row
                    sx={{ justifyContent: "center" }}
                    defaultValue="free"
                  >
                    <FormControlLabel
                      value="free"
                      control={<Radio />}
                      label="Free"
                    />
                    <FormControlLabel
                      value="pay"
                      control={<Radio />}
                      label="Premuim"
                    />
                  </RadioGroup>
                </Box>
                <TextField
                  fullWidth
                  disabled
                  value={0}
                  InputProps={{
                    endAdornment: <Typography>ADSX</Typography>
                  }}
                />
                <Box>
                  <TextField
                    fullWidth
                    disabled
                    value={100}
                    InputProps={{
                      endAdornment: <Typography>View</Typography>
                    }}
                  />
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 4, justifyContent: "center" }}>
          <Button variant="outlined">Add</Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleOnCloseDialigAddVideo}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default Channel;
